.css-transition {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}

.gray-transparent-background {
    background: rgba(234,234,234,1);
    background: -moz-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 4%, rgba(234,234,234,0.43) 26%, rgba(234,234,234,0) 50%, rgba(234,234,234,0) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(234,234,234,1)), color-stop(0%, rgba(234,234,234,1)), color-stop(4%, rgba(234,234,234,1)), color-stop(26%, rgba(234,234,234,0.43)), color-stop(50%, rgba(234,234,234,0)), color-stop(100%, rgba(234,234,234,0)));
    background: -webkit-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 4%, rgba(234,234,234,0.43) 26%, rgba(234,234,234,0) 50%, rgba(234,234,234,0) 100%);
    background: -o-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 4%, rgba(234,234,234,0.43) 26%, rgba(234,234,234,0) 50%, rgba(234,234,234,0) 100%);
    background: -ms-linear-gradient(left, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 4%, rgba(234,234,234,0.43) 26%, rgba(234,234,234,0) 50%, rgba(234,234,234,0) 100%);
    background: linear-gradient(to right, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 0%, rgba(234,234,234,1) 4%, rgba(234,234,234,0.43) 26%, rgba(234,234,234,0) 50%, rgba(234,234,234,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#eaeaea', GradientType=1 );
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.gray-transparent-background-reversed {
    background: rgba(234,234,234,0);
    background: -moz-linear-gradient(left, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 46%, rgba(234,234,234,0.43) 77%, rgba(234,234,234,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(234,234,234,0)), color-stop(0%, rgba(234,234,234,0)), color-stop(46%, rgba(234,234,234,0)), color-stop(77%, rgba(234,234,234,0.43)), color-stop(100%, rgba(234,234,234,1)));
    background: -webkit-linear-gradient(left, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 46%, rgba(234,234,234,0.43) 77%, rgba(234,234,234,1) 100%);
    background: -o-linear-gradient(left, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 46%, rgba(234,234,234,0.43) 77%, rgba(234,234,234,1) 100%);
    background: -ms-linear-gradient(left, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 46%, rgba(234,234,234,0.43) 77%, rgba(234,234,234,1) 100%);
    background: linear-gradient(to right, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 0%, rgba(234,234,234,0) 46%, rgba(234,234,234,0.43) 77%, rgba(234,234,234,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eaeaea', endColorstr='#eaeaea', GradientType=1 );
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.white-transparent-background {
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(252,252,252,0.7) 16%, rgba(252,252,252,1) 100%);
}

.white-transparent-background-flipped {
    background: rgb(255,255,255);
background: linear-gradient(90deg, rgba(255,255,255,1) 8%, rgba(252,252,252,0.7) 20%, rgba(252,252,252,0.7) 80%, rgba(252,252,252,0) 100%);
}